import { render, staticRenderFns } from "./scheme.vue?vue&type=template&id=05e107ba&scoped=true"
import script from "./scheme.vue?vue&type=script&lang=js"
export * from "./scheme.vue?vue&type=script&lang=js"
import style0 from "./scheme.vue?vue&type=style&index=0&id=05e107ba&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e107ba",
  null
  
)

export default component.exports